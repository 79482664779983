import Icon from './Icon';

export default function IcUserLogin({className}) {
  const ACCOUNT = (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 3.5C23.5 5.15685 22.1569 6.5 20.5 6.5C18.8431 6.5 17.5 5.15685 17.5 3.5C17.5 1.84315 18.8431 0.5 20.5 0.5C22.1569 0.5 23.5 1.84315 23.5 3.5ZM20.5 1.625C20.7071 1.625 20.875 1.79289 20.875 2V3.125H22C22.2071 3.125 22.375 3.29289 22.375 3.5C22.375 3.70711 22.2071 3.875 22 3.875H20.875V5C20.875 5.20711 20.7071 5.375 20.5 5.375C20.2929 5.375 20.125 5.20711 20.125 5V3.875H19C18.7929 3.875 18.625 3.70711 18.625 3.5C18.625 3.29289 18.7929 3.125 19 3.125H20.125V2C20.125 1.79289 20.2929 1.625 20.5 1.625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.8C10.5088 5.8 9.3 7.00883 9.3 8.5C9.3 9.99117 10.5088 11.2 12 11.2C13.4912 11.2 14.7 9.99117 14.7 8.5C14.7 7.00883 13.4912 5.8 12 5.8ZM7.5 8.5C7.5 6.01472 9.51472 4 12 4C14.4853 4 16.5 6.01472 16.5 8.5C16.5 10.9853 14.4853 13 12 13C9.51472 13 7.5 10.9853 7.5 8.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.3269C9.54514 15.3269 7.26576 16.0752 5.36843 17.3588C5.1024 17.5388 5.00032 17.7203 4.96212 17.8536C4.92241 17.9922 4.93045 18.1529 5.00485 18.3235C5.1556 18.6691 5.5855 19.0379 6.26676 19.0379H17.7332C18.4145 19.0379 18.8444 18.6691 18.9952 18.3235C19.0695 18.1529 19.0776 17.9922 19.0379 17.8536C18.9997 17.7203 18.8976 17.5388 18.6316 17.3588C16.7342 16.0752 14.4549 15.3269 12 15.3269ZM4.29186 15.7259C6.49797 14.2334 9.15014 13.3633 12 13.3633C14.8499 13.3633 17.502 14.2334 19.7081 15.7259C20.3079 16.1316 20.7194 16.6777 20.8995 17.3062C21.0781 17.9294 21.0091 18.5648 20.7682 19.1171C20.2883 20.2173 19.1342 21.0015 17.7332 21.0015H6.26676C4.86585 21.0015 3.71167 20.2173 3.23178 19.1171C2.99085 18.5648 2.92193 17.9294 3.10052 17.3062C3.28063 16.6777 3.69213 16.1316 4.29186 15.7259Z"
      />
    </svg>
  );

  return (
    <Icon className={className} label="Visitor">
      {ACCOUNT}
    </Icon>
  );
}

IcUserLogin.propTypes = {
  className: Icon.propTypes.className,
};

IcUserLogin.defaultProps = {
  className: '',
};
